import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";

import Layout from "../layout";
import SEO from "../functions/seo";
import { useStaticQuery, graphql } from "gatsby";

import Search from "../components/AC-Search/Search";

const SearchPage = () => {

  const data = useStaticQuery(graphql`
  query SupplierQuery {
      allSuppliersHasura {
        nodes {
          supplier_vendorId
          company_name
          description
          location
          city
          province
          country
          brand
          file {
            file_path
          }
          shipping_information {
            range
            shipping_type
            supplier_vendorId
          }
          pickup_locations {
            address_place
            latitude
            longitude
            pickup_location_name
          }
        }
      }
    }
  `);


  return (
    <Layout>
      <SEO title="Search" />
      <Search supplier={data.allSuppliersHasura.nodes} /> 
    </Layout>
  );
};

export default SearchPage;
