// extracted by mini-css-extract-plugin
export var wrapper = "Category_Search-module--wrapper--P7Fxv";
export var sortByPagination = "Category_Search-module--sortByPagination--IdHya";
export var mobileFilterBtn = "Category_Search-module--mobileFilterBtn--fSb2A";
export var subWrapper = "Category_Search-module--subWrapper--hx7vq";
export var numOfItemsWrapper = "Category_Search-module--numOfItemsWrapper--890JQ";
export var collectionsWrapper = "Category_Search-module--collectionsWrapper--U1isI";
export var placeHolderWrap = "Category_Search-module--placeHolderWrap--yGHfW";
export var mainImage = "Category_Search-module--mainImage--x6pwG";
export var otherImage = "Category_Search-module--otherImage--oO8-E";
export var wrapper2 = "Category_Search-module--wrapper2--VpsYH";