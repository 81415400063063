import React, { useEffect } from "react";
import { useDispatch} from "react-redux";

import Category from "../AC-CategoryPage/Category_Search";

import {
  fetchCategoryFromDirectUrl,
  changeKeyword,
} from "../../redux/actions/categoryActions.js";

import { useLocation } from "@reach/router";

export default function Search({supplier}) {

  const dispatch = useDispatch();
  const location = useLocation();

  let keyword = location.pathname;
  if (location.pathname.includes("search/"))
    keyword = location.pathname.split(`search/`)[1];

  useEffect(() => {
    dispatch(changeKeyword(keyword));
    // dispatch(changeCategoryName(keyword, [], "search", ""));
    dispatch(fetchCategoryFromDirectUrl());
  }, [keyword]);

  return <Category supplier={supplier} />;
}
